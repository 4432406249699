import React from "react"
import TemplatePageGuide  from "@components/pageGuide/template"
import { Helmet } from "react-helmet"
import data from "@components/pageGuide/guides/guide2/dataPageGuide2"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>¿Cómo planificar el primer contacto con el cliente? [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/guias/primer-contacto-cliente/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Te enseñamos a optimizar el primer contacto con el cliente para que puedas tener una mayor tasa de cierre de una venta."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="¿Cómo planificar el primer contacto con el cliente?"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/guias/primer-contacto-cliente/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/Guias/og-imgGuia2.jpg`}
      />
      <meta
        property="og:description"
        content="Te enseñamos a optimizar el primer contacto con el cliente para que puedas tener una mayor tasa de cierre de una venta."
      />
    </Helmet>
    <TemplatePageGuide
      ebook="Telemarketing"
      location={location}
      typeGuide="guide2"
      clickHereHref="https://beexcc.com/blog/noticias-atencion-al-cliente/"
      data={data}
    />
  </div>
)

export default IndexPage
